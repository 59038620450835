import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import styles from "../../../css/home/home.module.css";
import { payMethods } from "../../../helper/Helper";
import LineChart from "../../../components/LineChart";
import TopHeader from "../../../components/topheader/TopHeader";

const HeroSection = () => {
  const [activeMonth, setActiveMonth] = useState('jan')
  let months = ["jan", "feb", "mar", "apr", "may", "jun", "jul"];
  return (
    <div className={styles.heroSection_wrapper}>
    <TopHeader/>
      <div >
        <Row className="ps-2">
          <Col md="7" className="mt-5 px-5 ">
            <div className={styles.left_wrapper}>
            <h1 className={styles.header_text}>
              <span>Shield4Pay </span>
              Plateforme d'intégration de paiements en ligne pour votre entreprise!
            </h1>
            <p
              className={`text-white fw-light mt-3 w-75 ${styles.header_para_text}`}
            >
              Shield4Pay facilite l'intégration de divers services de paiement avec une API conviviale, permettant une gestion rapide et efficace des transactions en ligne pour votre entreprise, assurant des paiements sécurisés et optimisés.
            </p>
            <button className={`mt-4 ${styles.startbtn}`}>
              <div className={`d-flex align-items-center rounded-5 gap-2  ${styles.btnInside_wrap}`}>
                <p className={styles.btn_text}>COMMENCER</p> <div className={styles.circle}></div>
              </div>
            </button>
            </div>
            
          </Col>
          <Col md="5" className="d-flex  justify-content-end  mt-5 mt-md-0">
            <div
              className={`d-flex flex-column align-items-center position-relative ${styles.hero_right_Column}`}
            >
              <div
                className={`postion-aboslute d-flex align-items-center justify-content-center   ${styles.backgroundCircles}`}
                style={{
                  backgroundImage: `url(${require("../../../assets/images/Ellipse1.png")})`,
                }}
              >
                <div
                  className={`d-flex align-items-center justify-content-center  ${styles.backgroundCircles1}`}
                  style={{
                    backgroundImage: `url(${require("../../../assets/images/Ellipse2.png")})`,
                  }}
                >
                  <img
                    className={` w-50 h-50`}
                    src={require("../../../assets/images/Ellipse3.png")}
                    alt=""
                  />
                </div>
              </div>
              <div className={`p-3 mt-3 ${styles.analysisWrapper} `}>
                <div className="d-flex align-items-center justify-content-between">
                  <p className={`fw-medium text-white ${styles.title}`}>
                    Online Analysis
                  </p>
                  <select
                    name=""
                    id=""
                    className={`text-white ${styles.select_month}`}
                  >
                    <option value="">1 Month</option>
                    <option value="">2 Month </option>
                  </select>
                </div>
                <div className="d-flex align-items-center justify-content-between mt-3">
                  <p className={styles.chartData} style={{color: '#00D2AA'}}>
                    $ 2,334,67 <br /> <span>Income</span>
                  </p>
                  <p className={styles.chartData} style={{color: '#00D9F5'}}>
                    $ 5.31M <br /> <span>Expenses</span>
                  </p>
                </div>
                <div>
                  <LineChart height={'300'}/>
                </div>
                <ul className={styles.monthsList}>
                  {months?.map((item, index) => {
                    return <li key={index} onClick={()=> setActiveMonth(item)} className={`${activeMonth === item && styles.active_month }`} >{item}</li>;
                  })}
                </ul>
              </div>
              <div
                className={`py-4 px-3 me-auto ms-2 ms-sm-0 mt-5 ${styles.payMethods_wrap}`}
              >
                <h4 className="fs-6 text-white fw-regular">Pay Method</h4>
                <div className="d-flex align-items-center gap-2 mt-4">
                  {payMethods?.map((item, index) => {
                    return (
                      <div
                        key={index}
                        className={`${styles.pay_img} ${
                          index == "3" && styles.cover
                        }`}
                        style={{
                          backgroundImage: `url(${item})`,
                        }}
                      ></div>
                    );
                  })}
                </div>
              </div>
              <div className={`d-none d-md-block ${styles.robotHand}`}></div>
            </div>
          </Col>
        </Row>
        <Container className={`${styles.our_paymentMethod_wrapper}`}>
          <h4 className="fs-6 fw-regular text-white text-center py-5 ">
            Nos supports de paiement
          </h4>
          <Row className={`${styles.payments_row}`}>
            {new Array(5).fill("1").map((item, index) => {
              return (
                <Col className="d-flex justify-content-center  mt-2 mt-sm-0">
                  <img
                    className={`${styles.paymentMethods_icons}`}
                    src={require(`../../../assets/images/payment${
                      index + 1
                    }.png`)}
                  />
                </Col>
              );
            })}
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default HeroSection;
