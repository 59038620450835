import React from "react";
import styles from "./topheader.module.css";
import { useNavigate } from "react-router-dom";

const TopHeader = (props) => {
  const navigate = useNavigate();

  return (
    <div className={`d-flex align-items-center p-4 px-5`}>
      <div className={`${styles.siteLogo}`} />
      {props?.arrow && (
        <div onClick={()=> navigate(-1)} className={`ms-5 d-none d-md-block ${styles.back_arrow}`} />
      )}
      <div className="d-flex align-items-center ms-auto">
        <div className={styles.arrowIcon}></div>
        <a href="https://dashboard.shield4pay.com/" className="fs-5 fw-regular text-white ms-2">Espace client</a>
      </div>
    </div>
  );
};

export default TopHeader;
