import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import styles from "../../../css/home/home.module.css";
import { cardsImgs, customMade, subarr } from "../../../helper/Helper";

const SubscriptionSection = () => {
  return (
    <div className={`${styles.subscriptionSection_wrap}`}>
      <Container className={``}>
        <h1
          className={`text-center text-white pt-5 ${styles.subscriptionTitle}`}
        >
          Nos offres
        </h1>
        <p
          className={`text-center text-white fs-6 fw-regular py-3 ${styles.subscriptionpara}`}
        >
          Offres de paiement adaptées et sur mesure.
        </p>
        <Row className={`mt-5 `}>
          <Col
            md="7"
            className={`d-flex flex-column align-items-center   rounded-2 ${styles.leftCol}`}
          >
            <button className={`mt-3 ${styles.leftBtn}`}>STANDARD</button>
            <div className="d-flex align-items-center gap-3 mt-5">
              {cardsImgs?.map((item, index) => {
                return (
                  <div
                    key={index}
                    className={`${styles.cards_imgs}`}
                    style={{ backgroundImage: `url(${item})` }}
                  />
                );
              })}
            </div>
            <h1 className={`mt-4 ${styles.leftText}`}>5% + 0.25 €</h1>
            <ul className="w-100 px-5 mt-3">
              <h6 className="text-white fs-6 fw-regular">
                Business Acceptés :
              </h6>
              {subarr.map((item, index) => {
                return (
                  <li
                    key={index}
                    className={`d-flex fw-regular align-items-center gap-3 fs-6 text-white mt-4`}
                  >
                    {" "}
                    <div
                      className={`${styles.checkCircle}`}
                      style={{
                        backgroundImage: `url(${require("../../../assets/icons/checkCircle.png")})`,
                      }}
                    ></div>{" "}
                    {item}
                  </li>
                );
              })}
            </ul>
            <button className={`w-100 mt-5   ${styles.toStartbn}`}>
              Démarrer
            </button>
          </Col>
          <Col
            md="5"
            className={`d-flex flex-column align-items-center py-4 mt-5 mt-md-0 ${styles.rightCol}`}
          >
            <button className={` ${styles.leftBtn}`}>SUR MESURE</button>
            <h6 className={`fs-6 fw-bold mt-4  ${styles.rightCol_para}`}>
              Dix fonctionnalités essentielles
            </h6>
            <ul className="w-100 px-1 px-xl-5 mt-2">
              {customMade.map((item, index) => {
                return (
                  <li
                    key={index}
                    className={`d-flex fw-regular align-items-center gap-3 fs-6 text-white mt-3`}
                  >
                    {" "}
                    <div
                      className={`${styles.checkCircle}`}
                      style={{
                        backgroundImage: `url(${require("../../../assets/icons/checkCircle.png")})`,
                      }}
                    ></div>{" "}
                    {item}
                  </li>
                );
              })}
            </ul>
          </Col>
        </Row>
        <Row className={` ${styles.subSec_second_row}`}>
          <Col lg="7" md="6">
            <h1
              className={`fw-medium text-white text-center text-md-start ${styles.viewApiText}`}
            >
              Nous disposons d’une api solide fait pour s’intégrer au mieux à
              votre environnement.
            </h1>

            <button className={`mt-4 mx-auto mx-md-0 ${styles.apiBtn}`}>
              <div
                className={`d-flex align-items-center rounded-6 gap-2  ${styles.apibtn_inner_wrap}`}
              >
                <p className={styles.apibtn_text}>VOIR l’API</p>
                <div className={styles.circle}></div>
              </div>
            </button>
          </Col>
          <Col
            lg="5"
            md="6"
            className="d-flex justify-content-center mt-5 mt-md-0"
          >
            <div className="position-relative w-75 ">
              <div>
                <div className="d-flex align-items-center justify-content-between">
                  {" "}
                  <img
                    className={`${styles.siteToolsImg}`}
                    src={require("../../../assets/images/wocommerce.png")}
                    alt=""
                  />{" "}
                  <img
                    className={`${styles.siteToolsImg}`}
                    src={require("../../../assets/images/logoswebflow.png")}
                    alt=""
                  />
                </div>
                <div className="d-flex align-items-center justify-content-center">
                  {" "}
                  <img
                    className={`${styles.siteToolsImg}`}
                    src={require("../../../assets/images/logoswebflow.png")}
                    alt=""
                  />
                </div>

                <div className="d-flex align-items-center justify-content-between">
                  {" "}
                  <img
                    className={`${styles.siteToolsImg}`}
                    src={require("../../../assets/images/wocommerce.png")}
                    alt=""
                  />{" "}
                  <img
                    className={`${styles.siteToolsImg}`}
                    src={require("../../../assets/images/logoswebflow.png")}
                    alt=""
                  />
                </div>
              </div>
              <div
                className={` d-flex align-items-center justify-content-center opacity-25 top-0  ${styles.backgroundCircles} `}
                style={{
                  backgroundImage: `url(${require("../../../assets/images/Ellipse1.png")})`,
                }}
              >
                <div
                  className={`d-flex align-items-center justify-content-center   ${styles.backgroundCircles1}`}
                  style={{
                    backgroundImage: `url(${require("../../../assets/images/Ellipse2.png")})`,
                  }}
                >
                  <img
                    className={` w-50 h-50`}
                    src={require("../../../assets/images/Ellipse3.png")}
                    alt=""
                  />
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default SubscriptionSection;
