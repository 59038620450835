export const payMethods = [
  require("../assets/images/paypal.png"),
  require("../assets/images/apple .png"),
  require("../assets/images/visa.png"),
  require("../assets/images/shofipy.png"),
];
export const cardsImgs = [
  require("../assets/images/logocb.png"),
  require("../assets/images/MasterCardLogo.png"),
  require("../assets/images/ancienlogovisa.png"),
];
export const subarr = [
  "Transactions e-commerce",
  "Services basés sur l'abonnement",
  "Dons en ligne pour des associations à but non lucratif",
  "Paiements de réservations et de réservations",
  "Vente de produits numériques",
];

export const customMade = [
  "Transactions Sécurisées",
  "Support Multi-Devises",
  "Protection contre la Fraude",
  "Paiement Personnalisable",
  "Paiement Personnalisable",
  "Interface Adaptée aux Mobiles",
  "Transactions Sécurisées",
  "Support Multi-Devises",
  "Protection contre la Fraude",
  "Paiement Personnalisable",
  "Interface Adaptée aux Mobiles",
];
export const usefulLinks = [
  "Content",
  "How it Works",
  "Create",
  "Explore",
  "Terms & Services",
];
export const community = [
  "Help Center",
  "Partners",
  "Suggestions",
  "Blog",
  "Newsletters",
];
export const privacy = ["Our Partner", "Become a Partner"];
export const socialimgs = [
  require("../assets/icons/instagram.png"),
  require("../assets/icons/facebook.png"),
  require("../assets/icons/twitter.png"),
  require("../assets/icons/linkedin.png"),
];
export const contacts = [
  {
    img: require("../assets/icons/phone.png"),
    text: "+33 017 797 176",
    text2: "+33 017 797 176",
  },
  { img: require("../assets/icons/envelope.png"), text: "Support@mypay.com" },
  { img: require("../assets/icons/mapmarker.png"), text: "Paris, France" },
];

export const homeFooterData = [
  {
    name: "Useful Links",
    links: ["Content", "How it Works", "Create", "Explore", "Terms & Services"],
  },
  {
    name: "Community",
    links: ["Help Center", "Partners", "Suggestions", "Blog", "Newsletters"],
  },
  { name: "Privacy", links: ["Our Partner", "Become a Partner"] },
];

export const contactUsFooterData = [
  {
    name: "Liens",
    links: ["Statut", "API", "Support", "CGV", "Mentions légales"],
  },
  {
    name: "Communauté",
    links: [
      "Centre d’aide",
      "Partenaire",
      "Suggestions",
      "Blog",
      "Newsletters",
    ],
  },
  { name: "Partenaire", links: ["Nos partenaire", "Devenir partenaire"] },
];
