import React from "react";
import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import "./App.css";
import Home from "./pages/Home";
import Contactus from "./pages/Contactus.jsx";
import Login from "./pages/Login/index.jsx";
import SignUp from "./pages/SignUp/index.jsx";

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        {/*<Route path="/contactus" element={<Contactus />} />*}
        {/*<Route path="/login" element={<Login />} />*/}
        {/* <Route path="/" element={<SignUp />} /> */}


      </Routes>
    </BrowserRouter>
  );
};

export default App;
