import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import styles from "./footer.module.css";
import {
  community,
  homeFooterData,
  privacy,
  socialimgs,
  usefulLinks,
} from "../../helper/Helper";

const Footer = (props) => {
  const { para, bottom_para, data,name } = props;
  return (
    <div className={styles.wrapper} style={{ background: props?.bg }}>
      <Container className={`px-3 px-sm-0`}>
        <div className={`w-100 d-flex pb-4 flex-column flex-sm-row `}>
          <div className={`postion-relative ${styles.leftfirstCol}`}>
            <div className="d-flex align-items-center gap-3">
              <div className={`${styles.siteLogo}`} />
              <h4 className={styles.site_name}>{name}</h4>
            </div>
            <p className={`mt-4 w-75 ${styles.text}`}>{para}</p>

            <p className={`mt-5 postion-absolute bottom-0 ${styles.text}`}>
              {bottom_para}
            </p>
          </div>

          <Row style={{ flex: "1" }}>
            {data?.map((ele, index) => {
              return (
                <Col key={index} sm="4" className="mt-5 mt-sm-0">
                  <h6
                    className={`fs-6 fw-medium text-white mb-3 ${styles.listTitle}`}
                  >
                    {ele?.name}
                  </h6>
                  <ul className={`${styles.list}`}>
                    {ele?.links?.map((item, i) => {
                      return (
                        <li key={i} className={`mt-2 `}>
                          {item}
                        </li>
                      );
                    })}
                  </ul>
                </Col>
              );
            })}
            {/* <Col sm="4" className="mt-5 mt-sm-0">
              <h6
                className={`fs-6 fw-medium text-white mb-3 ${styles.listTitle}`}
              >
                Community
              </h6>
              <ul className={`${styles.list}`}>
                {community?.map((item, index) => {
                  return (
                    <li key={index} className={`mt-2 `}>
                      {item}
                    </li>
                  );
                })}
              </ul>
            </Col>
            <Col sm="4" className="mt-5 mt-sm-0">
              <h6
                className={`fs-6 fw-medium text-white mb-3 ${styles.listTitle}`}
              >
                Privacy
              </h6>
              <ul className={`${styles.list}`}>
                {privacy?.map((item, index) => {
                  return (
                    <li key={index} className={`mt-2 `}>
                      {item}
                    </li>
                  );
                })}
              </ul>
            </Col> */}
          </Row>
        </div>
        <Row className={`px-2 py-4 border-top border-dark`}>
          <Col sm='6'>
            <p className={` text-white fw-regular text-center text-sm-start pb-3 pb-sm-0 ${styles.copyright_text}`}>
              Copyright © 2024 Shield4Cloud SAS. All Rights Reserved.
            </p>
          </Col>
          <Col sm='6'>
            <div className="auto d-flex gap-3 align-items-center justify-content-center justify-content-sm-end">
              {socialimgs?.map((item, index) => {
                return (
                  <div
                    className={styles.socialLogo}
                    key={index}
                    style={{ backgroundImage: `url(${item})` }}
                  ></div>
                );
              })}
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Footer;
