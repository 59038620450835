import React from "react";
import { VictoryArea, VictoryChart, VictoryAxis } from "victory";

const LineChart = (props) => {
  const sampleData = [
    { x: 1, y: 5 },
    { x: 2, y: 3 },
    { x: 3, y: 2 },
    { x: 4, y: 1 },
    { x: 5, y: 2 },
    { x: 6, y: 3 },
    { x: 7, y: 6 },
    { x: 8, y: 3 },
    { x: 9, y: 1 },
    { x: 10, y: 2 },
    { x: 11, y: 2.5 },
    { x: 12, y: 1.5 },
  ];
  return (
    <VictoryChart
      width={2000}
      height={850}
      padding={{ top: 50 }}
      minDomain={{ y: -1 }}
    >
      {/* <linearGradient id="gradientStroke" x1="0%" y1="0%" x2="0%" y2="100%">
        <stop offset="0%" stopColor="#E3F8FD" stopOpacity="1" />
        <stop offset="100%" stopColor="#fff" stopOpacity="1" />
      </linearGradient> */}
      <VictoryArea
        interpolation="natural"
        style={{
          data: {
            stroke: "#00d2aa",
            fill: "transparent",
            strokeWidth: 10,
          },
        }}
        data={sampleData}
      />
      <VictoryAxis
        style={{
          axis: { stroke: "transparent" },
          ticks: { stroke: "transparent" },
          tickLabels: { fill: "transparent" },
        }}
      />
    </VictoryChart>
  );
};

export default LineChart;
