import React from "react";
import HeroSection from "./components/HeroSection";
import SubscriptionSection from "./components/SubscriptionSection";
import OurClients from "./components/OurClients";
import InformationForm from "./components/InformationForm";
import Footer from "../../components/footer/Footer";
import { homeFooterData } from "../../helper/Helper";

const Home = () => {
  return (
    <div>
      <HeroSection />
      <SubscriptionSection />
      <OurClients />

      <InformationForm />
      <Footer para="A new way to make the payments easy, reliable and secure."  data={homeFooterData}/>
    </div>
  );
};

export default Home;
