import React from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import styles from "../../../css/home/home.module.css";

const InformationForm = () => {
  return (
    <div className={`postion-relative ${styles.form_wrapper}`}>
      <Container className={styles.formContainer}>
        <h1 className="text-white text-center">Demande d’information</h1>
        <div className="w-75 mx-auto">
          <Row className="gap-3 mt-4 ">
            <Col md='5' className="z-3">
              <label
                className="fs-6 fw-bold text-white d-block mb-2"
                htmlFor="Nom"
              >
                Nom
              </label>
              <input
                className={`w-100 rounded-2 border-none ${styles.formInput}`}
                type="text"
                id="Nom"
                placeholder="Votre nom"
              />
            </Col>
            <Col md='5'>
              <label
                className="fs-6 fw-bold text-white d-block mb-2"
                htmlFor="Prenom"
              >
                Prenom
              </label>
              <input
                className={`w-100 rounded-2 border-none ${styles.formInput}`}
                type="text"
                id="Prenom"
                placeholder="Votre prénom"
              />
            </Col>
          </Row>
          <Row className="gap-4 mt-4">
            <Col md='5'>
              <label
                className="fs-6 fw-bold text-white d-block mb-2"
                htmlFor="Email"
              >
               Email
              </label>
              <input
                className={`w-100 rounded-2  ${styles.formInput}`}
                type="email"
                id="Email"
                placeholder="email@example.com"
              />
            </Col>
            <Col md='5'>
              <label
                className="fs-6 fw-bold text-white d-block mb-2"
                htmlFor="Nom de l’entreprise"
              >
                Nom de l’entreprise
              </label>
              <input
                className={`w-100 rounded-2 border-none ${styles.formInput}`}
                type="text" placeholder="Le nom de votre entreprise" id='Nom de l’entreprise'
              />
            </Col>
          </Row>
          <Row className="gap-4 mt-4">
            <Col md='5'>
              <label
                className="fs-6 fw-bold text-white d-block mb-2"
                htmlFor="Type de votre business"
              >
              Type de votre business
              </label>
              <input
                className={`w-100 rounded-2 border-none ${styles.formInput}`}
                type="text"
                id="Type de votre business"
                placeholder="Type"
              />
            </Col>
            <Col md='5'>
              <label
                className="fs-6 fw-bold text-white d-block mb-2"
                htmlFor="Siret"
              >
                Siret
              </label>
              <input
                className={`w-100 rounded-2 border-none ${styles.formInput}`}
                type="text"
                id="Siret"
                placeholder="45637XXXXXXX"
              />
            </Col>
          </Row>
          <Row className="gap-4 mt-4">
            <Col md='5'>
              <label
                className="fs-6 fw-bold text-white d-block mb-2"
                htmlFor="Chiffre d’affaire"
              >
                Chiffre d’affaire
              </label>
              <input
                className={`w-100 rounded-2 border-none ${styles.formInput}`}
                type="number"
                id="Chiffre d’affaire"
                placeholder="1.0000.000"
              />
            </Col>
            <Col md='5'>
              <label
                className="fs-6 fw-bold text-white d-block mb-2"
                htmlFor="Pays"
              >
                Pays
              </label>
              <input
                className={`w-100 rounded-2 border-none ${styles.formInput}`}
                type="text"
                id="Pays"
                placeholder="France"
              />
            </Col>
          </Row>
          <Row className="gap-4 mt-4">
            <Col md='5'>
              <label
                className="fs-6 fw-bold text-white d-block mb-2"
                htmlFor="Numéro de téléphone"
              >
                Numéro de téléphone
              </label>
              <input
                className={`w-100 rounded-2 border-none ${styles.formInput}`}
                type="tel"
                id="Numéro de téléphone"
                placeholder="(+33) 675 763 445"
              />
            </Col>
            <Col>
            <button className={`mt-4  ${styles.startbtn}`}>
              <div className={`d-flex align-items-center rounded-5 gap-3 px-3  ${styles.btnInside_wrap}`}>
                <p className={styles.btn_text}>Envoyer</p> <div className={styles.circle}></div>
              </div>
            </button>
           
              
            </Col>
          </Row>
        </div>
      </Container>
      <div className={` opacity-25  z-0 ${styles.side_globe}`}>

      </div>
    </div>
  );
};

export default InformationForm;
