import React from "react";
import styles from "../../../css/home/home.module.css";
import { Col, Container, Row } from "react-bootstrap";

const OurClients = () => {
  return (
    <div className={`${styles.ourclients_wrapper}`}>
      <Container className={`my-0 ${styles.our_paymentMethod_wrapper}`}>
        <h4 className="fs-1 fw-medium text-white text-center  pb-5 ">
          Nos clients
        </h4>
        <Row
          style={{ background: "transparent" }}
          className={`${styles.payments_row}`}
        >
          {new Array(5).fill("1").map((item, index) => {
            return (
              <Col className="d-flex justify-content-center">
                <img
                  className={`${styles.paymentMethods_icons}`}
                  src={require(`../../../assets/images/payment${
                    index + 1
                  }.png`)}
                />
              </Col>
            );
          })}
        </Row>
        <div
          className={`w-100 d-flex flex-column align-items-center rounded-3 py-3 px-5 ${styles.message_wrap}`}
        >
          <div className={` ${styles.topLogo}`} />
          <blockquote className="w-100 px-4 mt-5 text-center">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat. Duis aute irure dolor in
            reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
            pariatur.
          </blockquote>
          <div className="d-flex align-items-center mt-5">
            <div
              className={styles.profImg}
              style={{
                backgroundImage: `url(${require("../../../assets/images/Image.png")})`,
              }}
            />
            <p className={`fw-regular text-white ms-3 ${styles.profName}`}>
              Herman Jensen <br />
              <span className="text-secondary fw-light">CEO MyPay</span>
            </p>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default OurClients;
